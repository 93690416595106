/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

/**
 * Integration
 * @export
 * @class Integration
 */
export class Integration {
  public static readonly AIRWATCH: string = 'airwatch';
  public static readonly APTELIGENT: string = 'apteligent';
  public static readonly AZURE: string = 'azure';
  public static readonly BETTERMOBILE: string = 'bettermobile';
  public static readonly CARBONBLACK: string = 'carbonblack';
  public static readonly CHECKPOINT: string = 'checkpoint';
  public static readonly CROWDSTRIKE: string = 'crowdstrike';
  public static readonly DELL: string = 'dell';
  public static readonly EMPLOYEE_EXPERIENCE: string = 'employee_experience';
  public static readonly EXTREME_NETWORKS: string = 'extreme_networks';
  public static readonly HORIZON: string = 'horizon';
  public static readonly HUB: string = 'hub';
  public static readonly IDM: string = 'idm';
  public static readonly INTELLIGENCE: string = 'internal';
  public static readonly SERVICENOW_ITSM: string = 'servicenow_itsm';
  public static readonly LOOKOUT: string = 'lookout';
  public static readonly NETSKOPE: string = 'netskope';
  public static readonly PRADEO: string = 'pradeo';
  public static readonly UAG: string = 'uag';
  public static readonly WANDERA: string = 'wandera';
  public static readonly WS_ONE_MTD: string = 'workspace_one_mobile_threat_defense';
  public static readonly ZIMPERIUM: string = 'zimperium';
  public static readonly ZOOM: string = 'zoom';
  public static readonly ZSCALER: string = 'zscaler';
}
